@use 'sass:math';

$font-size-base: 16px;
$font-scale: 1.25;
$line-height-ratio: 1.4;
$padding-base: 16px;
$margin-base: 16px;

$font-size-h1: $font-size-base * math.pow($font-scale, 4); // 40px
$font-size-h2: $font-size-base * math.pow($font-scale, 3); // 32px
$font-size-h3: $font-size-base * math.pow($font-scale, 2); // 25.6px
$font-size-h4: $font-size-base * $font-scale; // 20px
$font-size-h5: $font-size-base * 1.125; // 18px
$font-size-h6: $font-size-base; // 16px
$font-size-body: $font-size-base; // 16px
$font-size-small: $font-size-base * 0.875; // 14px
$font-size-button: $font-size-base; // 16px

// Variables de font-weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

@mixin font-size($size) {
    font-size: $size;
}

@mixin line-height($size) {
    line-height: math.div($size, 1) * $line-height-ratio;
}

@mixin font-weight($weight) {
    font-weight: $weight;
}

@mixin padding($padding...) {
    $top: if(length($padding) >=1, nth($padding, 1), 0);
    $right: if(length($padding) >=2, nth($padding, 2), $top);
    $bottom: if(length($padding) >=3, nth($padding, 3), $top);
    $left: if(length($padding) >=4, nth($padding, 4), $right);
    padding: math.div($top, 1) math.div($right, 1) math.div($bottom, 1) math.div($left, 1);
}

@mixin margin($margin...) {
    $top: if(length($margin) >=1, nth($margin, 1), 0);
    $right: if(length($margin) >=2, nth($margin, 2), $top);
    $bottom: if(length($margin) >=3, nth($margin, 3), $top);
    $left: if(length($margin) >=4, nth($margin, 4), $right);
    margin: math.div($top, 1) math.div($right, 1) math.div($bottom, 1) math.div($left, 1);
}

html {
    font-size: $font-size-base;
}

h1 {
    @include font-size($font-size-h1);
    @include line-height($font-size-h1);
    @include font-weight($font-weight-bold);
    @include margin(0, 0, $margin-base, 0);
}

h2 {
    @include font-size($font-size-h2);
    @include line-height($font-size-h2);
    @include font-weight($font-weight-bold);
    @include margin(0, 0, $margin-base, 0);
}

h3 {
    @include font-size($font-size-h3);
    @include line-height($font-size-h3);
    @include font-weight($font-weight-regular);
    @include margin(0, 0, $margin-base, 0);
}

h4 {
    @include font-size($font-size-h4);
    @include line-height($font-size-h4);
    @include font-weight($font-weight-regular);
    @include margin(0, 0, $margin-base, 0);
}

h5 {
    @include font-size($font-size-h5);
    @include line-height($font-size-h5);
    @include font-weight($font-weight-regular);
    @include margin(0, 0, $margin-base, 0);
}

h6 {
    @include font-size($font-size-h6);
    @include line-height($font-size-h6);
    @include font-weight($font-weight-medium);
    @include margin(0, 0, $margin-base, 0);
}

p {
    @include font-size($font-size-body);
    @include line-height($font-size-body);
    @include font-weight($font-weight-regular);
    @include margin(0, 0, $margin-base, 0);
}

small {
    @include font-size($font-size-small);
    @include line-height($font-size-small);
    @include font-weight($font-weight-light);
    @include margin(0, 0, $margin-base, 0);
}

button {
    @include font-size($font-size-button);
    @include line-height($font-size-button);
    @include font-weight($font-weight-medium);
    @include padding(math.div($padding-base, 2), $padding-base);
    @include margin(0);
}