@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200&display=swap');
@import 'typography';
@import 'mixins';
@import 'colors';

body,
body .p-component {
  overflow-x: hidden !important;
  font-family: "Poppins", sans-serif;
  user-select: none;
  /* Disables text selection */
  -webkit-user-select: none;
  /* For WebKit (Safari, Chrome) */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For Internet Explorer/Edge */
}

p.sub {
  color: hsla(0, 0%, 10%, .7);
}

label.no-margin {
  margin-bottom: 0;
}